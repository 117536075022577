<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-card
          :title="$t('Users.List.title')"
        >
          <b-row
            class="d-flex flex-row"
          >
            <b-col
              cols="12"
              md="2"
              sm="4"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :label="$t('Users.List.perPage.label')"
                label-for="perPageSelect"
                :description="$t('Users.List.perPage.description')"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              sm="8"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Users.List.sort.description')"
                :label="$t('Users.List.sort.label')"
                label-for="sortBySelect"
              >
                <!-- TODO: Apply translation to this component-->
                <b-input-group>
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="align-items-center justify-content-end"
            >
              <b-form-group
                :description="$t('Users.List.filter.description')"
                :label="$t('Users.List.filter.label')"
                label-for="filterInput"
              >
                <b-input-group>
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    :placeholder="$t('Users.List.filter.placeholder')"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      variant="outline-primary"
                      @click="filter = ''"
                    >
                      {{ $t('Users.List.filter.buttonText') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(role)="data">
                  <b-badge :variant="status[0][data.value.toLowerCase()]">
                    {{ $t(`Users.roles.${data.value.toLowerCase()}`) }}
                  </b-badge>
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="status[0][data.value.toLowerCase()]">
                    {{ $t(`Users.statuses.${data.value.toLowerCase()}`) }}
                  </b-badge>
                </template>

                <template #cell(test_flag)="data">
                  <b-badge :variant="status[0][data.value]">
                    {{ data.value }}
                  </b-badge>
                </template>

                <template #cell(action)="row">
                  <b-button
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="modifyUser(row.item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="confirmUserDeletion(row.item)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </template>

              </b-table>
            </b-col>

            <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: this.$i18n.t('Users.List.table.fieldLabels.id') },
        { key: 'email', label: this.$i18n.t('Users.List.table.fieldLabels.email') },
        { key: 'role', label: this.$i18n.t('Users.List.table.fieldLabels.role') },
        { key: 'status', label: this.$i18n.t('Users.List.table.fieldLabels.status') },
        { key: 'action', label: this.$i18n.t('Users.List.table.fieldLabels.action') },
      ],
      items: [],
      status: [
        {
          administrator: 'primary',
          overseer: 'secondary',
          client: 'info',
          external: 'danger',
          collector: 'warning',
          active: 'light-success',
          transition: 'light-info',
          inactive: 'light-danger',
          // 2: 'light-info',
          // 3: 'success',
          // 4: 'warning',
          // 5: 'light-primary',
          // 6: 'dark',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    // Dato Mr Deivi, hacer esto para que primero cargue los datos y luego renderice.
    this.$http.get('/admin/users/count')
      .then(response => {
        this.totalRows = response.data.count
        this.$http.get('/admin/users/list', {
          params: {
            limit: this.totalRows,
          },
        })
          .then(response2 => {
            this.items = response2.data.entries
          })
          .catch(error => {
            console.log('There was an error:', error)
          })
      })
      .catch(error => {
        console.log('There was an error:', error)
      })
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // eslint-disable-next-line
    deleteUser(data) {
      // console.log('Example')
      // console.log(data)
    },
    // eslint-disable-next-line
    modifyUser(item) {
      // console.log(item)
    },
    confirmUserDeletion(item) {
      this.$swal({
        title: this.$i18n.t('SwAl.titles.confirmation'),
        html: this.$i18n.t('SwAl.htmls.confirmUserDeletion', { email: item.email }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('SwAl.buttonTexts.yes'),
        cancelButtonText: this.$i18n.t('SwAl.buttonTexts.no'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete('/admin/users/user', {
            params: {
              id: item.id,
            },
          })
            .then(() => {
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('SwAl.titles.deleted'),
                html: this.$i18n.t('SwAl.htmls.userDeleted', { email: item.email }),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                title: this.$i18n.t('SwAl.titles.error'),
                html: this.$i18n.t('SwAl.htmls.couldntDeleteUser', { email: item.email }),
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
